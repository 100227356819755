/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState, useCallback } from "react";
import {
  MyProvider,
  useSelector,
  CreateStore,
} from "../utils/react-global-state";
// import PoetryAbsenteeInputTimeDialog from "./PoetryAbsenteeInputTimeDialog.jsx";
// import { Dialog, DialogManager } from "../utils/Dialog";
import UserAgent from "../utils/UserAgent";
import CityArea from "../utils/city_area/CityArea.jsx";
import ChooseGradeModal from "../utils/choose_grade_modal/ChooseGradeModal.jsx";
// import ChoosePhase from "./ChoosePhase";
import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";

import BWDetect from "../utils/BWDetect.js";
import withAppPrivacy from "./WithAppPrivacy.jsx";
import { onMiniProgram, convertURLToJSON, onNavigate } from "../utils/helpers";
import speedCalcQrcode from "media_lesson_activity/mp_speed_calc.jpg";
import poetryAppLearn from "tips_download.png";
import * as Components from "./components";
import "./Index.scss";
import VConsole from "vconsole";

var vConsole = new VConsole();
const Index = memo(() => {
  const share = useSelector((state) => state.share);
  const grades = useSelector((state) => state.grades);
  const create_user_baby_grade = useSelector(
    (state) => state.create_user_baby_grade
  );

  // const user_activities = useSelector(state => state.user_activities);
  const userOwnedCourses = useSelector((state) => state.userOwnedCourses);
  const ownedCoursesCount = useSelector((state) => state.ownedCoursesCount);
  const getMoreOwnedCoursesUrl = useSelector(
    (state) => state.getMoreOwnedCoursesUrl
  );
  const mobileRequired = useSelector((state) => state.mobileRequired);
  const edit_mobile_url = useSelector((state) => state.edit_mobile_url);

  const study_package_system_time = useSelector(
    (state) => state.study_package_system_time
  );
  // const area_info_remind_required = useSelector(
  //   (state) => state.area_info_remind_required
  // );
  const baby = useSelector((state) => state.baby);
  const user = useSelector((state) => state.user);
  const login_path = useSelector((state) => state.login_path);
  const area = useSelector((state) => state.area);
  const ecoupons = useSelector((state) => state.ecoupons);
  const provinces = useSelector((state) => state.provinces);
  const user_url = useSelector((state) => state.user_url);

  const user_ongoing_activities = useSelector(
    (state) => state.user_ongoing_activities
  );

  const [isSpeedCalcObject, setIsSpeedCalcObject] = useState({});
  const [chooseGradeModalStatus, setChooseGradeModalStatus] = useState(false);
  const [choosePhaseShow, setChoosePhaseShow] = useState(false);

  const [absenteeActivity, setAbsenteeActivity] = useState("");

  const [
    displaySubPickerDialogStatus,
    setDisplaySubPickerDialogStatus,
  ] = useState(false);

  const [displayPickerDialogStatus, setDisplayPickerDialogStatus] = useState(
    false
  );

  const [showCitySelect, setShowCitySelect] = useState(false);
  const [city, setCity] = useState("");

  useEffect(() => {
    const { share_title, share_desc, share_image_url } = share;
    const share_url = window.location.href;

    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_desc,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });

    if (UserAgent.isMiniProgram()) {
      const urlString = convertURLToJSON(share_url);
      wx.miniProgram.postMessage({
        data: {
          title: share_title,
          imgUrl: share_image_url,
          link: urlString,
        },
      });
    }
  }, [share]);

  // 默认不弹年级选择
  // useEffect(() => {
  //   if (grades) {
  //     setChooseGradeModalStatus(true);
  //   }
  // }, [grades]);

  useEffect(() => {
    window.addEventListener("load", () => {
      BWDetect();
    });
  }, []);

  const choosePhaseClick = (phase) => {
    var params = {};
    // eslint-disable-next-line eqeqeq
    if (phase.id == -1) {
      params = {
        start_time: phase.start_time,
      };
    } else {
      params = {
        id: phase.id,
      };
    }
    if (absentee_activity.order_id) {
      params.order_id = absentee_activity.order_id;
    }
    if (absentee_activity.groupon_id) {
      params.groupon_id = absentee_activity.groupon_id;
    }
    if (absentee_activity) {
      params.absentee_activity_id = absentee_activity.id;
    }
    const toast = ToastManager.showLoading("请稍等");
    request({
      url: absentee_time_url, // TODO:
      data: params,
      method: "POST",
      headers: csrfHeaders,
    })
      .then((res) => {
        toast.cancel();
        window.location.href = res.data.redirect_url;
      })
      .catch((_err) => {
        toast.cancel();
      });
  };

  const activityTap = (e) => {
    const miniQRCode = e.currentTarget.getAttribute("data-mp-qrcode-url");
    const miniQRCodeLabel = e.currentTarget.getAttribute(
      "data-mp-qrcode-label"
    );
    const url = e.currentTarget.getAttribute("data-url");
    const isSpeedCalc = e.currentTarget.getAttribute("data-is-speed-calc");

    if (isSpeedCalc === "true" && !UserAgent.isMiniProgram()) {
      setIsSpeedCalcObject({
        alertCodeType: "whitePad",
        isAlertMiniQRCode: true,
        miniQRCode: speedCalcQrcode,
        miniQRCodeLabel: "请扫码开始练习",
      });
      return;
    }

    if (UserAgent.isWechat() || UserAgent.isMiniProgram()) {
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          if (url.includes("bargain_activities?id=2")) {
            wx.miniProgram.navigateTo({
              url: "/pages/calculate_training/list",
            });
          } else {
            onMiniProgram(e, url, {});
          }
        } else {
          // console.log("miniQRCode", miniQRCode);
          if (miniQRCode) {
            setIsSpeedCalcObject({
              alertCodeType: "",
              isAlertMiniQRCode: true,
              miniQRCode: poetryAppLearn,
              miniQRCodeLabel,
            });
          } else {
            window.location.href = url;
          }
        }
      });
    } else if (UserAgent.isWebBrowser()) {
      window.location.href = url;
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: url });
    }
    e.preventDefault();
  };

  // const onActivityStatusClick = (activity, e) => {
  //   console.log("----activity: ", activity);
  //   if (activity.course_type === "poetry" && activity.absentee) {
  //     e.stopPropagation();

  //     if (!activity.poetry_start_time_select_url) {
  //       if (UserAgent.isNativeClient()) {
  //         activity.user
  //           ? XinYaNativeBridge.navigate({
  //               href: `${activity.study_url}&ref=home`,
  //             })
  //           : XinYaNativeBridge.navigate({
  //               href: `${activity.buy_url}&ref=home`,
  //             });
  //       } else {
  //         activity.user
  //           ? (window.location.href = `${activity.study_url}&ref=home`)
  //           : (window.location.href = `${activity.buy_url}&ref=home`);
  //       }
  //       return;
  //     }

  //     setAbsenteeActivity(activity);
  //     setChoosePhaseShow(true);
  //   }
  // };

  // 弹小程序课程二维码
  const onAlertMini = (isAlert) => {
    setIsSpeedCalcObject({
      isAlertMiniQRCode: isAlert,
    });
  };

  const onCloseAbsenteeStartTimeInputModal = () => {
    setChoosePhaseShow(false);
  };

  const rendeQrPhonePrompt = () => {
    const { alertCodeType, miniQRCode, miniQRCodeLabel } = isSpeedCalcObject;
    return (
      <div className="alert_qr_code">
        <div
          className="qr_phone_prompt"
          style={
            alertCodeType === "whitePad"
              ? { background: "white", top: "27vh" }
              : {}
          }
        >
          <div
            className="close_minicode"
            onClick={() => onAlertMini(false)}
            style={alertCodeType === "whitePad" ? { right: -15, top: -40 } : {}}
          ></div>
          {alertCodeType === "whitePad" && (
            <p style={{ paddingTop: "12px", fontSize: "15x" }}>
              {miniQRCodeLabel}
            </p>
          )}
          <img
            data-src={miniQRCode}
            className="lazyload"
            style={alertCodeType === "whitePad" ? { width: 250 } : {}}
          />
        </div>
      </div>
    );
  };

  // 显示、关闭 · 选择年级弹框
  const closeChooseGradeModalStatus = (boolState) => {
    console.log("closeChooseGradeModalStatus", boolState);
    setChooseGradeModalStatus(boolState);
  };

  const updateUserAddress = (city) => {
    setCity(city);
  };

  // 父组件中 displayPickerDialogStatus 直接控制 dialog的显隐
  const displayPickerDialog = (bool = false) => {
    setDisplayPickerDialogStatus(bool);
  };

  // const getChoosePhaseShowStatus = boolState => {
  //   setChoosePhaseShow(boolState);
  // };

  // 需要传递给子组件调用的方法
  const displaySubPickerDialog = () => {
    setDisplaySubPickerDialogStatus(true);
  };

  const WithoutUser = (
    <div className="none_user">
      <span className="none_user_text">请登录查看已报名的课程</span>
      <a href={login_path} className="none_user_loginBtn">
        登录
      </a>
    </div>
  );

  const handleCourseClick = useCallback((e) => {
    const url = e.currentTarget.getAttribute("data-url");
    onNavigate(e, url);
  }, []);

  return (
    <div className="new_study_package_activity_list">
      {!user ? (
        WithoutUser
      ) : (
        <Components.UserInfo
          onMiniProgramClick={activityTap}
          user={user}
          baby={baby}
          area={area}
          ecoupons={ecoupons}
          provinces={provinces}
          city={city}
          displaySubPickerDialog={displaySubPickerDialog}
        />
      )}
      {user_ongoing_activities && user_ongoing_activities.length > 0 && (
        <Components.BuyStatusTips
          onMiniProgramClick={activityTap}
          userOngoingActivities={user_ongoing_activities}
          systemTime={study_package_system_time}
        />
      )}
      {mobileRequired && (
        <Components.BindMobile
          mobileRequired={mobileRequired}
          edit_mobile_url={edit_mobile_url}
        />
      )}
      {!!userOwnedCourses && (
        <Components.OwnedCourses
          // onActivityStatusClick={onActivityStatusClick}
          onMiniProgramClick={activityTap}
          // userActivities={user_activities}
          userOwnedCourses={userOwnedCourses}
          systemTime={study_package_system_time}
          ownedCoursesCount={ownedCoursesCount}
          getMoreOwnedCoursesUrl={getMoreOwnedCoursesUrl}
        />
      )}
      <Components.AllCourses
        openChooseGradeModalStatus={closeChooseGradeModalStatus}
        handleCourseClick={handleCourseClick}
        mobileRequired={mobileRequired}
      />

      {
        // 点击弹框相应的二维码
        isSpeedCalcObject.isAlertMiniQRCode && rendeQrPhonePrompt()
      }
      {
        // 选择年级
        grades && chooseGradeModalStatus && (
          <ChooseGradeModal
            grades={grades}
            createUserBabyGrade={create_user_baby_grade}
            chooseGradeModalStatus={closeChooseGradeModalStatus}
          />
        )
      }

      {(showCitySelect || displaySubPickerDialogStatus) &&
        (displayPickerDialogStatus || displaySubPickerDialogStatus) && (
          <CityArea
            provinces={provinces}
            area={area}
            user_url={user_url}
            updateUserAddress={updateUserAddress}
            displayPickerDialog={displayPickerDialog}
          />
        )}
      {/* {choosePhaseShow && (
        // 针对古诗课程，“选择开课日期”状态下，在当前弹框，提醒明天开课
        <PoetryAbsenteeInputTimeDialog
          activity={absenteeActivity}
          onDialogClose={onCloseAbsenteeStartTimeInputModal}
        />
      )} */}
      {/* {absentee_activity && (
        // 注：这块逻辑之后要重新处理
        <ChoosePhase
          getChoosePhaseShowStatus={getChoosePhaseShowStatus}
          choosePhaseShow={choosePhaseShow}
          absenteeActivity={absentee_activity} // 注： 此absentee_activity和上面那个不一样
          sureBtnAction={choosePhaseClick}
          closeBtnAction={null}
        />
      )} */}
    </div>
  );
});

const WithProvider = (props) => {
  console.log("props WithProvider", props);
  const [showUpper, setShowUpper] = useState(true);
  return (
    <MyProvider
      store={
        new CreateStore(
          {
            ...props,
            share: props.share,
            user_activities: props.user_activities,
            grades: props.grades,
            create_user_baby_grade: props.create_user_baby_grade,
            campaigns: props.campaigns,
            study_package_system_time: props.study_package_system_time,
            // area_info_remind_required: props.area_info_remind_required,
            baby: props.baby,
            user: props.user,
            login_path: props.login_path,
            area: props.area,
            ecoupons: props.ecoupons,
            provinces: props.provinces,
            user_url: props.user_url,
            userOwnedCourses:
              props.user_activities && props.user_activities.length > 0
                ? props.user_activities
                : null,
            mobileRequired: props.mobile_required,
            edit_mobile_url: props.edit_mobile_url,
            user_ongoing_activities: props.user_ongoing_activities,
            getCategoryGroupsUrl: props.get_category_groups_url,
            getRecommendCoursesUrl: props.get_recommend_courses_url,
            ownedCoursesCount: props.user_activities_count,
            getMoreOwnedCoursesUrl: props.get_user_activities_url,
            categoryGroupTimeLastUpdateTime:
              props.last_category_group_update_time,
          },
          () => {}
        )
      }
    >
      <Index />
      {/* {console.log(
        props.upgrade_app_url,
        "--------------------",
        showUpper,
        "------------------------------------",
        !window.localStorage.hiddenUpperTag
      )} */}
      {showUpper && props.upgrade_app_url && (
        <div className="upper-icon">
          <div className="upper-icon-back-img">
            {/* 更新提示====================== */}
            <div className="upper-icon-back-tips">更新提示</div>
            {/* 更新内容 */}
            <div className="upper-icon-back-text">
              {/* <div className="upper-icon-back-title">
                  更新了以下内容:
                </div> */}
              {/* <div>
                  {[
                    "上课时间提醒设定时，APP闪退",
                    "需多次重复登录",
                    "数学课错题本订正后，数据未更新",
                  ].map((item, index) => {
                    return (
                      <div
                        className="upper-icon-back-text-item"
                        key={"b" + index}
                      >
                        <div className="upper-icon-back-dote"></div>
                        <div className="upper-icon-back-text-item-con">
                          {item}
                        </div>
                      </div>
                    );
                  })}
                </div> */}
              <div style={{ margin: "20px 0px 30px 0px" }}>
                <div>APP已有新版可下载</div>
                <div>为避免课程无法学习，请升级更新APP哦</div>
              </div>
            </div>
            {/* 更新按钮 ============ */}
            <div
              className="upper-icon-back-text-item-button"
              onClick={() => {
                if (UserAgent.isAndroid()) {
                  XinYaNativeBridge.navigate({
                    href: props.upgrade_app_url,
                    type: "update",
                  });
                } else {
                  XinYaNativeBridge.navigate({
                    href: props.upgrade_app_url,
                  });
                }
              }}
            >
              立即升级
            </div>
            {/* 更新按钮 ============*/}
            {/* 取消按钮 */}
            <div
              className="upper-icon-back-text-item-cancle"
              onClick={() => {
                console.log("..........cancle");
                setShowUpper(false);
                // window.localStorage.setItem("hiddenUpperTag", true);
              }}
            ></div>
            {/* 取消按钮 ===============*/}
          </div>
          {/* 更新提示====================== */}
        </div>
      )}
    </MyProvider>
  );
};

export default withAppPrivacy(WithProvider);
