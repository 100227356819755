import React, { useEffect, useState } from "react";
import {
  setLocalStorage,
  getLocalStorage,
  showDialog,
} from "../utils/helpers.js";
import UserAgent from "../utils/UserAgent.js";
import PrivacyDialog from "./PrivacyDialog.jsx";
import XinYaNativeBridge from "@/packs/native_bridges/native_bridge";

const withAppPrivacy = (Comp) => {
  const WithAppPrivacyModal = (props) => {
    const [isShowAppPrivacyModal, setIsShowAppPrivacyModal] = useState(false);

    useEffect(() => {
      if (
        !props.user &&
        getLocalStorage("privacyAccepted") !== "true" &&
        UserAgent.isNativeClient()
      ) {
        setIsShowAppPrivacyModal(true);
      }
    }, [props.user]);

    useEffect(() => {
      if (isShowAppPrivacyModal) {
        const dialogOptions = {
          text: <PrivacyDialog />,
          mainLabel: "同意",

          mainCallback: () => {
            setLocalStorage("privacyAccepted", "true");
            XinYaNativeBridge.onPrivacyAccepted();
          },
          subLabel: "暂不使用",
          subCallback: () => {
            XinYaNativeBridge.exitApp(0);
          },
        };
        showDialog.call(this, dialogOptions);
      }
    }, [isShowAppPrivacyModal]);

    return <Comp {...props} />;
  };

  return WithAppPrivacyModal;
};

export default withAppPrivacy;
